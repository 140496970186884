import { useEffect, useState } from 'react';
import {
  FaCalendarAlt,
  FaRegEye
} from 'react-icons/fa';
import Image, { KEY_IMAGE } from 'src/components/image';
import { APP_IMG, NEXT_DEFAULT_COUNT_VIEW } from 'src/constants';
import { sendDataLayerGlobal } from 'src/utils/datalayer/send-datalayer';
import { useDateUpdate, usePageViews } from 'src/utils/helper';
import { incrementCountview } from 'src/utils/increment-countview';

const CardV = ({
  data,
  path,
  cardV = false,
  breakingNews = false,
  textColor = '',
  showBadge = false,
  showPublished = false,
  showBlurb = false,
  showPageviews = true,
  external = false,
  eventDataLayer
}) => {
  if (!data) return null;
  const [countView, setCountView] = useState(0);

  useEffect(async () => {
    await incrementCountview(data)
      .then(item => setCountView(item))
      .catch(() => setCountView(0));
  }, []);

  const dateCheck = data?.published_at || data.dateDefault;
  const dateUpdate = useDateUpdate(dateCheck);
  const category = data.subCategory?.th ? data.subCategory?.th : data?.section?.th;

  return (
    <>
      <a tabIndex='-1' href={path} onClick={() => sendDataLayerGlobal({ ...eventDataLayer })}>
        <div className='card-wrapper'>
          <div className={`card-v ${textColor} ${textColor ? 'mb-unset' : ''}`}>
            <div className='thumbnail-img'>
              <Image external={external} image={data?.image_list?.xl ?? data?.image ?? '/default.webp'} title={data?.title} type={KEY_IMAGE.CARD_MEDIUM} />
              {(showBadge && category) && <div className='tag-box --sm top-left'>{category}</div>}
              {data?.is_video && <div className='play-btn'>
                <img src={`${APP_IMG}/images/play.png`} alt='play' width='100%' height='100%' />
              </div>
              }
            </div>
            <div className={`${textColor ? 'post-date-light' : 'post-date-dark'} ${breakingNews ? 'breaking-color' : ''} mt-2`}>
              <ul>
                {showPublished && <li><FaCalendarAlt /><span>{dateUpdate}</span></li>}
                {showPageviews && <>{countView > NEXT_DEFAULT_COUNT_VIEW && <li>
                  <FaRegEye /><span>{usePageViews(countView)}</span>
                </li>}</>}
              </ul>
            </div>
            <div className={`${cardV ? 'custom-font-card-v' : ''} ${breakingNews ? 'breaking-color' : ''} with-inline-block`}>
              <h3 >{data?.title}</h3>
            </div>
            {showBlurb && <span className='blurb'>{data.blurb}</span>}
          </div>
        </div>
      </a>
    </>
  );
};
export default CardV;
